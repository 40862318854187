






















































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DrawerFooter from '@/components/drawers/DrawerFooter.vue'

// STORE
import RepairModule from '@/store/modules/fleet/repair'
import SystemMixin from '@/mixins/SystemMixin'

// INTERFACES
import { CarParksRepairCarParkIdGetParams, IRepairFilterForm, NameValueResource } from '@/store/types'
import { tableOptionsToFilters } from '@/utils/functions'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'

@Component({
  components: {
    DrawerFooter,
  },
})
export default class RepairFilterForm extends Mixins(SystemMixin) {
  private form: IRepairFilterForm = GET_DEFAULT_TABLE_FILTER()

  private get filtersDictionary () {
    return RepairModule.dictionaries
  }

  private get activeFilters (): CarParksRepairCarParkIdGetParams {
    return tableOptionsToFilters(RepairModule.filters)
  }

  private get activeFiltersCount () {
    let count = 0
    for (let item in this.form){
      if (this.form[item as keyof CarParksRepairCarParkIdGetParams] && !(item.includes('endDate') || item.includes('startDate'))) {
        count++
      }
    }
    return count
  }

  private get stateNumbers (): NameValueResource[] {
    return this.filtersDictionary.stateNumber.map(item => ({
      name: item.name.slice(0,6) + ' ' + item.name.slice(6),
      value: item.value,
    }))
  }

  private get transferPeriod (): any {
    const period = []
    if (this.form.transferStartDate && this.form.transferEndDate){
      period.push(this.form.transferStartDate)
      period.push(this.form.transferEndDate)
    }
    return period
  }

  private set transferPeriod (value: any) {
    this.form.transferStartDate = value[0]
    this.form.transferEndDate = value[1]
  }

  private get returnPeriod (): any {
    const period = []
    if (this.form.returnStartDate && this.form.returnEndDate){
      period.push(this.form.returnStartDate)
      period.push(this.form.returnEndDate)
    }
    return period
  }

  private set returnPeriod (value: any) {
    this.form.returnStartDate = value[0]
    this.form.returnEndDate = value[1]
  }

  private get plannedReturnPeriod (): any {
    const period = []
    if (this.form.plannedReturnStartDate && this.form.plannedReturnEndDate){
      period.push(this.form.plannedReturnStartDate)
      period.push(this.form.plannedReturnEndDate)
    }
    return period
  }

  private set plannedReturnPeriod (value: any) {
    this.form.plannedReturnStartDate = value[0]
    this.form.plannedReturnEndDate = value[1]
  }

  private created () {
    RepairModule.getFiltersDictionary(+this.entity)

    this.form = {
      ...this.form,
      ...this.activeFilters,
    }
  }

  private clearFilters () {
    this.form = {
      ...GET_DEFAULT_TABLE_FILTER(),
      vin: '',
      orderNumber: '',
      stateNumber: '',
      carModelId: '',
      regionId: '',
      repairCost: undefined,
      returnStartDate: '',
      returnEndDate: '',
      transferStartDate: '',
      transferEndDate: '',
      plannedReturnStartDate: '',
      plannedReturnEndDate: '',
    }

    this.applyFilters(false)
  }

  private applyFilters (close = true) {
    RepairModule.setFilters(this.form)
    this.$bus.$emit('changeRepairFilters', this.form)

    if (close){
      this.$closeDrawer()
    }
  }
}
